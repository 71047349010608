import { createContext } from 'react';

export const languages = 
[
  {
    "home": "Home",
    "ventajas": "Services",
    "ejemplos": "Clients",
    "main-text-h1": "We create amazing websites that inspire",
    "main-text-h2": "Stand out from your competitors with a spectacular design",
    "section-one-h3-01": "Are you insatisfied with late releases and not meeting the requirements?",
    "section-one-h3-02": "We are with you from the beginning to the final release on time until your total satisfaction.",
    "section-two-title-01": "Unique Websites",
    "section-two-title-02": "Hosting & Domain Name",
    "section-two-title-03": "Initial SEO",
    "section-two-title-04": "Secure Server",
    "section-two-text-01": "You will have the website you are dreaming, from the begining until the final release.",
    "section-two-text-02": "Hosting and domain name included. Or you can keep your favourite hosting if you have one.",
    "section-two-text-03": "Ready-to-be-found in the search engines. Content-ready for SEO.",
    "section-two-text-04": "We work with the best servers, with SSL certificate and fast performance.",
    "projects-title": "Some of our amazing websites",
    "projects-description-h3-01": "Hotel: Balcón de Córdoba",
    "projects-description-p-01": "Hotel connected to the booking central. Secure payment, image gallery, and user forum.",
    "projects-description-h3-02": "Tintarantin", 
    "projects-description-p-02": "Online store, purchase of downloadable digital products by subscription and blog.",
    "ask-budget-text": "Ask for a budget!",
    "contact-now": "Contact now!",
    "contacto": "Contact",
    "change-language": "Change language",
    "contact-us": "Contact and tell us more about you!",
    "sending": "sending...",
    "placeholder-first-name": "First Name",
    "placeholder-last-name": "Last Name",
    "placeholder-email": "your@email.com",
    "placeholder-your-message": "Your message",
    "submit": "Submit"
  },
  {
    "home": "Inicio",
    "ventajas": "Ventajas",
    "ejemplos": "Ejemplos",
    "main-text-h1": "Creamos páginas web de impacto",
    "maint-text-h2": "Destaca entre tus competidores con un diseño espectacular",
    "section-one-h3-01": "Te acompañamos desde el principio hasta la entrega final.",
    "section-two-title-01": "Webs personalizadas",
    "section-two-title-02": "Hosting y dominio",
    "section-two-title-03": "SEO inicial incluido",
    "section-two-title-04": "Servidor Seguro",
    "section-two-text-01": "Desde el concepto inicial hasta el final, tendrás la web que quieres.",
    "section-two-text-02": "Alojamiento y dominio incluidos. Si ya tienes una web puedes seguir utilizando tu servidor actual.",
    "section-two-text-03": "Tu web estará preparada para ser encontrada en todos los buscadores, con un buen posicionamiento SEO de su contenido.",
    "section-two-text-04": "Contratamos los mejores servidores, con certificado SSL incluido y alta velocidad de descarga.",
    "projects-title": "Ejemplos de nuestros clientes",
    "projects-description-h3-01": "Hotel: Balcón de Córdoba",
    "projects-description-p-01": "Hotel conectado a la central de reservas. Pago seguro, galería de imágenes y foro de usuarios.",
    "projects-description-h3-02": "Tintarantin", 
    "projects-description-p-02": "Tienda online, compra de productos de descarga digital por subscriptión y blog.",
    "ask-budget-text": "",
    "contact-now": "Contacta ahora",
    "contacto": "Contacto",
    "change-language": "Cambiar idioma",
    "contact-us": "Pídenos un presupuesto",
    "sending": "...enviando...",
    "placeholder-first-name": "Nombre",
    "placeholder-last-name": "Apellidos",
    "placeholder-email": "tu@email.com",
    "placeholder-your-message": "Cuéntanos en qué podemos ayudarte.",
    "submit": "Enviar"
  }
];
  
export const LocalizationContext = createContext(
  null // default value
);

