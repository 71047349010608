import { useState, useEffect, useContext } from 'react';
import logo from './images/logo.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LocalizationContext, languages } from './LocalizationContext';
import MenuBurger from './MenuBurger';

function Header() {
  const { lang, setLang } = useContext(LocalizationContext);

  // CHECK WINDOW SIZE
  const [menuClass, setMenuClass] = useState('');

  useEffect(() => {
    function changeMenuClass() {
      window.innerWidth >= 500
        ? setMenuClass('big-screen')
        : setMenuClass('small-screen');
    }
    changeMenuClass();
  }, [window.innerWidth]);

  // SCROLLING EFFECT
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let navbar = document.getElementById('navbar');
      let menu = document.getElementById('menu');
      // Si la ventana es grande se ejecuta un tipo de menu
      if (menuClass === 'big-screen') {
        if (window.pageYOffset >= menu.offsetTop) {
          // downscroll code
          navbar.classList.add('sticky');
          setScrolling(false);
        } else {
          // upscroll code
          navbar.classList.remove('sticky');
          setScrolling(true);
        }
      } else {
        // Si la ventana es pequeña se ejecuta el menú burguer:
        if (window.pageYOffset >= menu.offsetTop) {
          // downscroll code
          navbar.classList.add('stickyBurguer');
          setScrolling(false);
        } else {
          // upscroll code
          navbar.classList.remove('stickyBurguer');
          setScrolling(true);
        }
      }

      setScrollTop(window.pageYOffset <= 0 ? 0 : window.pageYOffset);
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  // SWEETALERT

  const showForm = () => {
    Swal.fire({
      title: 'Contacto',
      html: `<input type="text" id="name" class="swal2-input" placeholder="Nombre completo">
      <input type="email" id="email" class="swal2-input" placeholder="E-mail">
      <textarea id="texto" class="swal2-input" placeholder="Describe lo que necesitas" />`,
      confirmButtonText: 'Sign in',
      focusConfirm: false,
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#name').value;
        const email = Swal.getPopup().querySelector('#email').value;
        const texto = Swal.getPopup().querySelector('#texto').value;
        if (!name || !email || !texto) {
          Swal.showValidationMessage(`Please enter login and password`);
        }
        return { name: name, email: email };
      },
    }).then((result) => {
      Swal.fire(
        `
        Name: ${result.value.name}
        Email: ${result.value.email}
        Text: ${result.value.texto}
      `.trim()
      );
    });
  };

  return (
    <header className='app-header'>
      <nav className='nav-bar' id='navbar'>
        <a href='/'>
          <img className='logo' src={logo} alt='Mensalia Technologies Ltd.' />
        </a>
        {menuClass === 'big-screen' ? (
          <ul className='menu' id='menu'>
            <li>
              <a href='' id='home'>
                {languages[lang]['home']}
              </a>
            </li>
            <li>
              <a href='#section-one'>{languages[lang]['ventajas']}</a>
            </li>
            <li>
              <a href='#products'>{languages[lang]['ejemplos']}</a>
            </li>
            <li>
              <a href='#contact'>{languages[lang]['contacto']}</a>
            </li>
            
          </ul>
        ) : (
          /* MENU FOR MOBILES */
          <MenuBurger noOverlay logo={logo} right />
        )}
      </nav>
      
      <div className='main-text' id='main-text'>
        <h1>{languages[lang]['main-text-h1']}</h1>
        <h2>{languages[lang]['main-text-h2']}</h2>
      </div>
      {/*       <div className='main-text' id='main-text'>
        <h3>
          <Button
            variant='outline-primary'
            className='buttom-budget'
            href='#contact'
            onClick={() => showForm()}
          >
            Pide presupuesto ahora
          </Button>
        </h3>
      </div> */}

      
    </header>
  );
}

export default Header;
