import { useContext } from 'react';
import { FaWhatsappSquare } from 'react-icons/fa';
import { BsTelephoneForwardFill } from 'react-icons/bs';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { LocalizationContext, languages } from './LocalizationContext';

function Footer({ toggleLanguage }) {
  const { lang } = useContext(LocalizationContext);

  return (
    <footer className='footer' id='footer'>
      {/* LANG BUTTON */}

      <div className='menu-lang'>
              <button
                className='btn language'
                type='button'
                onClick={() => {
                  toggleLanguage();
                }}
              >
                <img className='btn-language-image' src={`./images/flag${lang}.png`} alt="We speak English / Hablamos español" />
              </button><span className="change-language-text">{languages[lang]['change-language']}</span>
            </div>
      <div className="ask-budget-text"><a href="#contact">{languages[lang]['ask-budget-text']}</a></div>
      <ul className='menu-footer'>
        {lang === 1 ? (
          <>
          <li className='email'>
            <a href='#contact'>
              <MdOutlineMarkEmailRead color='lightgreen' fontSize='3.2em' />
            </a>
          </li>
          <li className='phone'>
            <a href='tel:+34613793578'>
              <BsTelephoneForwardFill color='lightblue' fontSize='2.5em' />
            </a>
          </li>
          </>
        ) : null}
        <li className='whatsapp'>
          <a href='https://wa.me/+34613793578'>
            <FaWhatsappSquare color='lightgreen' fontSize='3em' />
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
