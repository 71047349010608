import '../css/contact-form-styles.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import CustomForm from './CustomForm';

const MailChimpFormContainer   = ( props ) => {
/*   const postUrl = `https://mensalia.us10.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
 */
const postUrl = `https://mensalia.us10.list-manage.com/subscribe/post?u=1c5112e369c0dce51df9bf8c0&amp;id=220d17c02c`;

  if (process.env.REACT_APP_MAILCHIMP_ID === '220d17c02c') {
    console.log('FOUND!');
  } else {
    console.log('NOT FOUND!');
  }

  return (
    <div className='mc__form-container'>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailChimpFormContainer;
