import { useState, useEffect } from 'react';
import Header from './Header';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import Projects from './Projects';
import MailChimpFormContainer from './ContactForm/MailChimpFormContainer';
import Footer from './Footer';
import { useScrollDirection } from './useScroll';
import { LocalizationContext } from './LocalizationContext';

function App() {
  const [lang, setLang] = useState(0);

  useEffect(() => {
      // Set the initial value of language
      let browserLanguage = window.navigator.language || navigator.userLanguage;
      if (browserLanguage.includes('es')) {
        setLang(1);
      } else {
        setLang(0);
      }
  },[]);

  useScrollDirection((position) => {
    let footer = document.getElementById('footer');
    if (position === 'SCROLL_DIRECTION_UP') {
      footer.classList.add('sticky');
    } else {
      footer.classList.remove('sticky');
    }
  });

  /* LANGUAGE STATE */
  const toggleLanguage = () => {
    lang === 0 ? setLang(1) : setLang(0);

    // 0 Inglés
    // 1 Español
  };
  return (
    <LocalizationContext.Provider value={{ lang, setLang }}>
      <div className='App'>
        <Header />
        <SectionOne />
        <SectionTwo />
        <Projects />
        <MailChimpFormContainer />
        <Footer toggleLanguage={toggleLanguage} />
      </div>
    </LocalizationContext.Provider>
  );
}

export default App;
