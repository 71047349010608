import { useScrollPosition } from './useScroll';
import { useContext } from 'react';
import { LocalizationContext, languages } from './LocalizationContext';

function SectionTwo() {
  /*   useScrollPosition((position) => {
    let sectionTwo = document.getElementById('section-two');
    if (position >= 640) {
      sectionTwo.classList.add('effectUp');
    } else {
      sectionTwo.classList.remove('effectUp');
    }
  }); */

  const { lang } = useContext(LocalizationContext);

  const nums = ['uno', 'dos', 'tres', 'cuatro'];
  const title = [`${languages[lang]['section-two-title-01']}`, `${languages[lang]['section-two-title-02']}`, `${languages[lang]['section-two-title-03']}`, `${languages[lang]['section-two-title-04']}`];
  const text = [`${languages[lang]['section-two-text-01']}`, `${languages[lang]['section-two-text-02']}`, `${languages[lang]['section-two-text-03']}`, `${languages[lang]['section-two-text-04']}`
  ];
  return (
    <section className='section-two' id='section-two'>
      <div className='section-two-wrapper'>
      {nums.map((card, key) => (
        <div className='card-feature' key={key}>
          <div className='number-feature'>
            {console.log(card)}
            <img src={require(`./images/${card}.png`)} className='features-img' alt='' />
          </div>
          <div className='card-feature-title'>{title[key]}</div>
          <div>
            <hr />
          </div>
          <div className='card-feature-text'>{text[key]}</div>
        </div>
      ))}
      </div>
    </section>
  );
}

export default SectionTwo;
