/* import { useState } from 'react';
import { BsGithub, BsLink45Deg } from 'react-icons/bs'; */

/* import 'bootstrap/dist/css/bootstrap.min.css'; */
import { useContext } from 'react';
import { LocalizationContext, languages } from './LocalizationContext';
import GoogleMaps from './images/googlemaps_anim.gif';
import Tintarantin from './images/tintarantin_anim.gif';
import ShoppingCart from './images/shoppingcart_anim.gif';
import BalconDeCordoba from './images/balcondecordoba_anim.gif';
import NeyaSound from './images/neyasound_anim.gif';

function Projects() {
  
  const { lang } = useContext(LocalizationContext);

  return (
    <>
      <div className='container' id='products'>

        <div><h4>{languages[lang]['projects-title']}</h4></div>
        <div className='wrapper-projects'>
          <div className='projects-description'>
            <div><h3>{languages[lang]['projects-description-h3-01']}</h3>
            <p>{languages[lang]['projects-description-p-01']}</p>
            <p><a href="https://www.balcondecordoba.com/"><img src={BalconDeCordoba} className="img-carousel" alt=''/></a></p>
            </div>
          </div>
          <div className='projects-description'>
            <div><h3>{languages[lang]['projects-description-h3-02']}</h3>
            <p>{languages[lang]['projects-description-p-02']}</p>
            <p><a href='https://www.tintarantin.com/'><img src={Tintarantin} className="img-carousel" alt=''/></a></p>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
}
export default Projects;
