import { useContext } from 'react';
import workingTogether from './images/working-together.jpg';
import { useScrollPosition } from './useScroll';
import { LocalizationContext, languages } from './LocalizationContext';

function SectionOne() {
  const { lang } = useContext(LocalizationContext);

  useScrollPosition((position) => {
    let sectionOne = document.getElementById('section-one');
    if (position >= 320) {
      sectionOne.classList.add('effectScrollCenter');
    } else {
      sectionOne.classList.remove('effectScrollCenter');
    }
  });

  return (
    <section className='section-one' id='section-one'>
      <div>
        <h3>
          <p>
            <strong>{languages[lang]['section-one-h3-01']}</strong>
          </p>
          <p>{languages[lang]['section-one-h3-02']}</p>
        </h3>
      </div>
      <div>
        <img className='together-img' src={workingTogether} />
      </div>
    </section>
  );
}

export default SectionOne;
