import '../css/contact-form-styles.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import InputField from '../ContactForm/InputField';
import { useState } from 'react';
import { LocalizationContext, languages } from '../LocalizationContext';
import { useContext } from 'react';

const CustomForm = ({ status, message, onValidated }) => {

  const { lang } = useContext(LocalizationContext);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf('@') > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE6: text,
      });
  };

  return (
    <form id="contact" className='mc__form' onSubmit={(e) => handleSubmit(e)}>
      <h3 className='mc__title'>{languages[lang]['contact-us']}</h3>

      {/*  Error handling */}
      
      {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            {languages[lang]['sending']}
          </div>
        )}
        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: "There was an error sending the message. Type correctly your email." }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: "Thank you for you message! We will answer you as soon as possible." }}
          />
        )}

        
      <div className='mc__field-container'>
        <InputField
          label='First Name'
          onChangeHandler={setFirstName}
          type='text'
          value={firstName}
          placeholder={`${languages[lang]['placeholder-first-name']}`}
          isRequired
        />

        <InputField
          label='Last Name'
          onChangeHandler={setLastName}
          type='text'
          value={lastName}
          placeholder={`${languages[lang]['placeholder-last-name']}`}
          isRequired
        />

        <InputField
          label='Email'
          onChangeHandler={setEmail}
          type='email'
          value={email}
          placeholder={`${languages[lang]['placeholder-email']}`}
          isRequired
        />
        <InputField
          label='Message'
          onChangeHandler={setText}
          type='textarea'
          value={text}
          placeholder={`${languages[lang]['placeholder-your-message']}`}
        />

        <InputField
          label={`${languages[lang]['submit']}`}
          type='submit'
          formValues={[email, firstName, lastName, text]}
        />
      </div>
    </form>
  );
};
export default CustomForm;

//MailchimpFormContainer Parent Component Below
