import { pushRotate as Menu } from 'react-burger-menu';
import { useContext } from 'react';
import { LocalizationContext, languages } from './LocalizationContext';

function MenuBurger({ logo }) {
  const { lang, setLang } = useContext(LocalizationContext);
  return (
    <>
      <Menu>
        <a href='/'>
          <img
            className='logo burger'
            src={logo}
            alt='Mensalia Technologies Ltd.'
          />
        </a>
        <a id='home' className='menu-item' href='/'>
          {languages[lang]['home']}
        </a>
        <a id='about' className='menu-item' href='#section-one'>
          {languages[lang]['ventajas']}
        </a>
        <a id='clients' className='menu-item' href='#products'>
          {languages[lang]['ejemplos']}
        </a>
        <a className='menu-item' href='#contact'>
          {languages[lang]['contact-now']}
        </a>
      </Menu>
      </>
  );
}
export default MenuBurger;
